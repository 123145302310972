import { environment } from '../../environments/environment';

export const AppConfig = {
  apiUrl: environment.apiUrl,
  version: '1.10.0',
  isTest: !environment.production && environment.isTest,
  isDemo: environment.isDemo,
  defaultStorageMode: 'local',
  googleClientId: '491005062625-9oeue3aq2p0jkgfb2s6malikuuv5ik1u.apps.googleusercontent.com',
  bugsnagApiKey: '8f5db08227e54925f0a47cc940fee22c',
};
