import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

interface CacheState {
  myUtenti: UtenteSimpleDto[] | null;
}

@Injectable({
  providedIn: 'root',
})
export class OicCacheService {
  // Map per contenere i BehaviorSubject, keyed by string.
  // Il valore è BehaviorSubject<unknown> per generalità, il casting avviene nei metodi.
  private cache = new Map<keyof CacheState, BehaviorSubject<unknown>>();

  constructor() {
    console.log('OicCacheService INSTANCE CREATED');
  }

  /**
   * Ottiene l'Observable per una chiave cache definita in CacheState.
   * @param key Chiave valida definita in CacheState.
   * @returns Observable del tipo corrispondente alla chiave in CacheState (o null).
   */
  getData<K extends keyof CacheState>(key: K): Observable<CacheState[K]> {
    if (!this.cache.has(key)) {
      // Inizializza con null se la chiave è nuova. Il cast è necessario.
      this.cache.set(key, new BehaviorSubject<CacheState[K]>(null as CacheState[K]));
    }

    // Ritorna l'observable castato al tipo corretto CacheState[K]
    return this.cache.get(key)!.asObservable() as Observable<CacheState[K]>;
  }

  /**
   * Ottiene il valore corrente per una chiave cache definita in CacheState.
   * @param key Chiave valida definita in CacheState.
   * @returns Il valore corrente (può essere null).
   */
  getCurrentValue<K extends keyof CacheState>(key: K): CacheState[K] {
    if (!this.cache.has(key)) {
      // Ritorna null (compatibile con il tipo CacheState[K] che include | null)
      return null as CacheState[K];
    }
    return this.cache.get(key)!.getValue() as CacheState[K];
  }

  /**
   * Imposta/aggiorna i dati per una chiave cache definita in CacheState.
   * @param key Chiave valida definita in CacheState.
   * @param data I dati da memorizzare (non dovrebbero essere null qui).
   */
  setData<K extends keyof CacheState>(key: K, data: NonNullable<CacheState[K]>): void {
    if (!this.cache.has(key)) {
      this.cache.set(key, new BehaviorSubject<CacheState[K]>(null as CacheState[K]));
    }

    this.cache.get(key)!.next(data);
  }

  /**
   * Pulisce i dati per una chiave specifica (imposta a null).
   * @param key Chiave valida definita in CacheState.
   */
  clearData<K extends keyof CacheState>(key: K): void {
    if (this.cache.has(key)) {
      this.cache.get(key)!.next(null);
    }
  }

  /** Pulisce l'intera cache (imposta tutti i subject noti a null). */
  clearAll(): void {
    this.cache.forEach((subject) => subject.next(null));
  }

  /**
   * Verifica se per una chiave esiste un valore cachato (diverso da null).
   * @param key Chiave valida definita in CacheState.
   * @returns boolean True se esiste un valore non nullo.
   */
  hasData<K extends keyof CacheState>(key: K): boolean {
    return this.cache.has(key) && this.cache.get(key)!.getValue() !== null;
  }
}
